// Need to hoist public path setting to top so ES6 imports do not come before it.
import './publicPath';
// This is used to expose PHAF global declarations.
import {init as initL10n} from '@verily-src/l10n/src/l10n';
import '@verily-src/phaf-runtime-helpers';

import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';

initL10n(f => require(`./l10n/locales/${f}`));

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return <div>Oops! Something went wrong.</div>;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;
export {Assistant} from './components/Assistant/Assistant';
export {ConversationalAssistant} from './components/Assistant/ConversationalAssistant';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
