import {Box, Typography, useTheme} from '@mui/material';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';
import messageLoading from '../../../assets/messageLoading.gif';
import {useAssistantConfigContext} from '../../ConfigContext/ConfigContext';

type MessageBubbleProps = {
  message: string;
  isLoading: boolean;
  backgroundColor: string;
  borderColor: string;
  fontColor?: string | undefined;
  sender: 'user' | 'assistant';
};

export function MessageBubble(props: MessageBubbleProps) {
  const theme = useTheme();
  const {supportMarkdown} = useAssistantConfigContext();
  const ref = useRef<HTMLElement>(null);
  const {message, backgroundColor, borderColor, fontColor, isLoading, sender} =
    props;
  const {t} = useTranslation();

  useEffect(() => {
    if (message && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [message]);

  const markdownMessage = message.replace('\n', '\n\n');

  const defaultTypographyProps = {
    sx: {
      color: fontColor,
    },
    ['data-testid']: 'message',
    gutterBottom: true,
  };

  let shouldRenderMarkdown = false;
  let shouldRenderPlainText = false;

  if (!isLoading) {
    shouldRenderMarkdown = !!supportMarkdown && sender === 'assistant';
    shouldRenderPlainText = sender === 'user' || !supportMarkdown;
  }

  return (
    <Box
      data-testid="message-bubble"
      tabIndex={0}
      ref={ref}
      sx={{
        '&:focus': {
          outline: 0,
        },
        outline: 0,
        padding: theme.spacing(2),
        borderRadius: '24px',
        borderTopLeftRadius: '4px',
        borderWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        display: 'flex',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        color: fontColor,
      }}
    >
      {isLoading && (
        <Box data-testid="loading">
          <img
            src={messageLoading}
            alt={t('loading')}
            style={{
              height: theme.typography.pxToRem(24),
            }}
          />
        </Box>
      )}
      {shouldRenderMarkdown && (
        <Markdown
          className="line-break"
          data-testid="markdown-message"
          components={{
            h1: props => (
              <Typography variant="h4" {...defaultTypographyProps} {...props} />
            ),
            h2: props => (
              <Typography variant="h5" {...defaultTypographyProps} {...props} />
            ),
            h3: props => (
              <Typography variant="h6" {...defaultTypographyProps} {...props} />
            ),
            h4: props => (
              <Typography variant="h6" {...defaultTypographyProps} {...props} />
            ),
            h5: props => (
              <Typography variant="h6" {...defaultTypographyProps} {...props} />
            ),
            h6: props => (
              <Typography variant="h6" {...defaultTypographyProps} {...props} />
            ),
            p: props => (
              <Typography
                variant="body1"
                {...defaultTypographyProps}
                {...props}
                gutterBottom={false}
              />
            ),
            strong: props => (
              <Typography
                component="strong"
                {...defaultTypographyProps}
                {...props}
                sx={{
                  ...defaultTypographyProps.sx,
                  fontWeight: 'bold',
                }}
              />
            ),
            em: props => (
              <Typography
                component="em"
                {...defaultTypographyProps}
                {...props}
              />
            ),
            li: props => (
              <Typography
                component="li"
                {...defaultTypographyProps}
                {...props}
              />
            ),
          }}
        >
          {`${markdownMessage}`}
        </Markdown>
      )}
      {shouldRenderPlainText && (
        <Typography
          data-testid="message"
          variant="body1"
          sx={{
            color: fontColor,
          }}
        >
          {`${message}`}
        </Typography>
      )}
    </Box>
  );
}
