import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function Topic() {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Typography>
        <span>{t('chat-topic-text-in')}</span>{' '}
        <span
          style={{
            color: theme.palette.text.muted,
            fontStyle: 'italic',
            fontWeight: 'bold',
          }}
        >
          {t('chat-topic-text-type-1-diabetes')}
        </span>
      </Typography>
    </Box>
  );
}
