import {Card, useTheme} from '@mui/material';
import React from 'react';
import useIsMobile from '../../../utilities/useIsMobile';
import useIsTablet from '../../../utilities/useIsTablet';
import {ChatDrawer} from '../ChatDrawer/ChatDrawer';

type MaybeDrawerProps = {
  children?: React.ReactNode;
  isMobileEmbed?: boolean;
  onClose: () => void;
};

const MAX_WIDTH = 572;
const MIN_WIDTH = 343;

function MaybeDrawer(props: MaybeDrawerProps) {
  const theme = useTheme();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  if (!isMobile && !isTablet) {
    return (
      <Card
        data-testid="chat-card"
        variant="outlined"
        sx={{
          padding: 0,
          borderRadius: '24px',
          marginBottom: theme.spacing(2),
          maxWidth: MAX_WIDTH,
          minWidth: MIN_WIDTH,
          width: '100%',
          alignSelf: 'flex-start',
        }}
      >
        {props.children}
      </Card>
    );
  }

  return (
    <ChatDrawer
      isMobileEmbed={props.isMobileEmbed}
      onClose={() => {
        props.onClose();
      }}
    >
      {props.children}
    </ChatDrawer>
  );
}

export {MaybeDrawer};
