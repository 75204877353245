import {Avatar, Box, Typography, useTheme} from '@mui/material';
import {MessageBubble} from '../MessageBubble/MessageBubble';

type UserMessageProps = {
  message: string;
  initials: string;
};

export function UserMessage(props: UserMessageProps) {
  const theme = useTheme();
  const {message} = props;

  return (
    <Box
      data-testid="user-message"
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
        }}
      >
        <Box>
          <Avatar
            data-testid="avatar"
            style={{
              width: '28px',
              height: '28px',
              fontSize: theme.typography.overline.fontSize,
              color: theme.palette.text.muted,
            }}
          >
            {props.initials}
          </Avatar>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          }}
        >
          <Box>
            <Typography variant="body2">You</Typography>
          </Box>
          <MessageBubble
            sender="user"
            message={message}
            borderColor={theme.palette.primary.main}
            backgroundColor={theme.palette.primary.main}
            fontColor={theme.palette.primary.textOnMain}
            isLoading={false}
          />
        </Box>
      </Box>
    </Box>
  );
}
