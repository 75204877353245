import {Box, Card, Chip, Typography, useTheme} from '@mui/material';
import {Button, ChevronRightIcon} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';
import greenBlob from '../../../assets/greenBlob.svg';
import useIsMobile from '../../../utilities/useIsMobile';

interface SplashScreenProps {
  onButtonClick: () => void;
}

export default function SplashScreen(props: SplashScreenProps) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  const titleVariant = isMobile ? 'display6' : 'display5';
  const titleFontSize = isMobile
    ? theme.typography.pxToRem(18)
    : theme.typography.pxToRem(21);

  const topicLabels = [
    t('topic-symptoms'),
    t('topic-treatment'),
    t('topic-resources'),
    t('topic-common-terms'),
    t('topic-disease-management'),
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        padding: 0,
        // TODO(ONEVERILY-37669): [Cleanup] Width x Height pixels to constants
        maxWidth: 572,
        minWidth: 343,
        width: '100%',
        borderRadius: '24px',
        marginBottom: theme.spacing(2),
        height: 'max-content',
      }}
      data-testid="assistant-splash-screen"
    >
      <Box
        sx={{
          paddingTop: theme.spacing(2),
          paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(3),
          paddingRight: isMobile ? theme.spacing(2) : theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            paddingBottom: theme.spacing(3),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexGrow: 1,
                  alignSelf: 'stretch',
                  lineHeight: 1.33,
                }}
              >
                {/* Must use h2 for accessibility heading structure */}
                <Typography
                  variant={titleVariant}
                  sx={{fontSize: titleFontSize}}
                  component="h2"
                >
                  {t('have-a-question')}
                </Typography>
                <Typography
                  variant={titleVariant}
                  sx={{fontSize: titleFontSize}}
                  component="h2"
                >
                  {t('verily-me-assistant-is-here-to-help')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '110px',
                  padding: '11.5px 7px 9.5px 7px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '96px',
                    height: '96px',
                    alignItems: 'center',
                  }}
                >
                  <img src={greenBlob} alt={t('assistant-title-short')} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            onClick={props.onButtonClick}
            variant="filled"
            sx={{
              padding: '16px 16px 16px 24px',
              fontSize: theme.typography.pxToRem(16),
            }}
            label={t('ask-your-question')}
            icon={<ChevronRightIcon />}
            iconPosition="end"
            pendo-id="enter-chatbot-assistant-button"
          />
          <Box
            sx={{
              padding: 0,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {t('you-can-ask-about')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                padding: '0px',
                gap: theme.spacing(1),
                flexDirection: 'row',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                flexWrap: 'wrap',
              }}
            >
              {topicLabels.map((label, index) => (
                <Chip
                  key={index}
                  label={label}
                  sx={{
                    backgroundColor: theme.palette.background.canvas,
                    fontSize: theme.typography.pxToRem(14),
                    paddingLeft: theme.typography.pxToRem(4),
                    paddingRight: theme.typography.pxToRem(4),
                    paddingTop: theme.typography.pxToRem(12),
                    paddingBottom: theme.typography.pxToRem(12),
                    '& .MuiChip-label': {
                      whiteSpace: 'normal',
                      overflow: 'visible',
                      textOverflow: 'clip',
                      overflowWrap: 'break-word',
                      lineHeight: '115%',
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.muted,
            }}
          >
            {t('assistant-cta-disclaimer')}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
