// TODO: Remove this component as it is only for a demo in October 2024.

import {ArrowBackIos} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

export function TitleBar() {
  return (
    <AppBar
      data-testid="title-bar"
      position="fixed"
      variant="outlined"
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: theme => theme.palette.background.paper,
        borderWidth: 0,
      }}
    >
      <Toolbar>
        <IconButton
          size="small"
          edge="start"
          aria-label="menu"
          sx={{
            mr: 2,
            color: theme => theme.palette.text.primary,
            backgroundColor: theme => theme.palette.background.canvas,
            display: 'flex',
            width: '32px',
            height: '32px',
            paddingLeft: '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ArrowBackIos />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography
            component="div"
            variant="body1em"
            sx={{
              textAlign: 'center',
            }}
          >
            Check in on your goal
          </Typography>
        </Box>
        <Button
          sx={{
            fontSize: theme => theme.typography.body1.fontSize,
          }}
        >
          Help
        </Button>
      </Toolbar>
    </AppBar>
  );
}
