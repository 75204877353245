/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "assistant/bff/api/v1/assistant_bff_service.proto" (package "assistant.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AssistantBFFService } from "./assistant_bff_service";
import type { GetAssistantsResponse } from "./assistant_bff_service";
import type { GetAssistantsRequest } from "./assistant_bff_service";
import type { AssistantConfiguration } from "./assistant_bff_service";
import type { GetAssistantConfigurationRequest } from "./assistant_bff_service";
import type { AskMultiTurnQuestionResponse } from "./assistant_bff_service";
import type { AskMultiTurnQuestionRequest } from "./assistant_bff_service";
import type { CreateMultiTurnSessionResponse } from "./assistant_bff_service";
import type { CreateMultiTurnSessionRequest } from "./assistant_bff_service";
import type { AskQuestionResponse } from "./assistant_bff_service";
import type { AskQuestionRequest } from "./assistant_bff_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateSessionResponse } from "./assistant_bff_service";
import type { CreateSessionRequest } from "./assistant_bff_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * A dedicated service for serving requests from the Assistant MFE.
 *
 * @generated from protobuf service assistant.bff.api.v1.AssistantBFFService
 */
export interface IAssistantBFFServiceClient {
    /**
     * Used to create a new single-turn session with the Assistant.
     *
     * @generated from protobuf rpc: CreateSession(assistant.bff.api.v1.CreateSessionRequest) returns (assistant.bff.api.v1.CreateSessionResponse);
     */
    createSession(input: CreateSessionRequest, options?: RpcOptions): UnaryCall<CreateSessionRequest, CreateSessionResponse>;
    /**
     * Used to ask a single-turn question to the Assistant for a given session.
     *
     * @generated from protobuf rpc: AskQuestion(assistant.bff.api.v1.AskQuestionRequest) returns (assistant.bff.api.v1.AskQuestionResponse);
     */
    askQuestion(input: AskQuestionRequest, options?: RpcOptions): UnaryCall<AskQuestionRequest, AskQuestionResponse>;
    /**
     * Used to create a new multi-turn session with the Assistant.
     *
     * @generated from protobuf rpc: CreateMultiTurnSession(assistant.bff.api.v1.CreateMultiTurnSessionRequest) returns (assistant.bff.api.v1.CreateMultiTurnSessionResponse);
     */
    createMultiTurnSession(input: CreateMultiTurnSessionRequest, options?: RpcOptions): UnaryCall<CreateMultiTurnSessionRequest, CreateMultiTurnSessionResponse>;
    /**
     * Used to ask a multi-turn question to the Assistant for a given session.
     *
     * @generated from protobuf rpc: AskMultiTurnQuestion(assistant.bff.api.v1.AskMultiTurnQuestionRequest) returns (assistant.bff.api.v1.AskMultiTurnQuestionResponse);
     */
    askMultiTurnQuestion(input: AskMultiTurnQuestionRequest, options?: RpcOptions): UnaryCall<AskMultiTurnQuestionRequest, AskMultiTurnQuestionResponse>;
    /**
     * Used to get the Assistant configuration to determine if Assistant is enabled for a given user.
     *
     * @generated from protobuf rpc: GetAssistantConfiguration(assistant.bff.api.v1.GetAssistantConfigurationRequest) returns (assistant.bff.api.v1.AssistantConfiguration);
     */
    getAssistantConfiguration(input: GetAssistantConfigurationRequest, options?: RpcOptions): UnaryCall<GetAssistantConfigurationRequest, AssistantConfiguration>;
    /**
     * Used to get the list of assistants available to a given user.
     *
     * @generated from protobuf rpc: GetAssistants(assistant.bff.api.v1.GetAssistantsRequest) returns (assistant.bff.api.v1.GetAssistantsResponse);
     */
    getAssistants(input: GetAssistantsRequest, options?: RpcOptions): UnaryCall<GetAssistantsRequest, GetAssistantsResponse>;
}
/**
 * A dedicated service for serving requests from the Assistant MFE.
 *
 * @generated from protobuf service assistant.bff.api.v1.AssistantBFFService
 */
export class AssistantBFFServiceClient implements IAssistantBFFServiceClient, ServiceInfo {
    typeName = AssistantBFFService.typeName;
    methods = AssistantBFFService.methods;
    options = AssistantBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Used to create a new single-turn session with the Assistant.
     *
     * @generated from protobuf rpc: CreateSession(assistant.bff.api.v1.CreateSessionRequest) returns (assistant.bff.api.v1.CreateSessionResponse);
     */
    createSession(input: CreateSessionRequest, options?: RpcOptions): UnaryCall<CreateSessionRequest, CreateSessionResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateSessionRequest, CreateSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Used to ask a single-turn question to the Assistant for a given session.
     *
     * @generated from protobuf rpc: AskQuestion(assistant.bff.api.v1.AskQuestionRequest) returns (assistant.bff.api.v1.AskQuestionResponse);
     */
    askQuestion(input: AskQuestionRequest, options?: RpcOptions): UnaryCall<AskQuestionRequest, AskQuestionResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AskQuestionRequest, AskQuestionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Used to create a new multi-turn session with the Assistant.
     *
     * @generated from protobuf rpc: CreateMultiTurnSession(assistant.bff.api.v1.CreateMultiTurnSessionRequest) returns (assistant.bff.api.v1.CreateMultiTurnSessionResponse);
     */
    createMultiTurnSession(input: CreateMultiTurnSessionRequest, options?: RpcOptions): UnaryCall<CreateMultiTurnSessionRequest, CreateMultiTurnSessionResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateMultiTurnSessionRequest, CreateMultiTurnSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Used to ask a multi-turn question to the Assistant for a given session.
     *
     * @generated from protobuf rpc: AskMultiTurnQuestion(assistant.bff.api.v1.AskMultiTurnQuestionRequest) returns (assistant.bff.api.v1.AskMultiTurnQuestionResponse);
     */
    askMultiTurnQuestion(input: AskMultiTurnQuestionRequest, options?: RpcOptions): UnaryCall<AskMultiTurnQuestionRequest, AskMultiTurnQuestionResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<AskMultiTurnQuestionRequest, AskMultiTurnQuestionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Used to get the Assistant configuration to determine if Assistant is enabled for a given user.
     *
     * @generated from protobuf rpc: GetAssistantConfiguration(assistant.bff.api.v1.GetAssistantConfigurationRequest) returns (assistant.bff.api.v1.AssistantConfiguration);
     */
    getAssistantConfiguration(input: GetAssistantConfigurationRequest, options?: RpcOptions): UnaryCall<GetAssistantConfigurationRequest, AssistantConfiguration> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAssistantConfigurationRequest, AssistantConfiguration>("unary", this._transport, method, opt, input);
    }
    /**
     * Used to get the list of assistants available to a given user.
     *
     * @generated from protobuf rpc: GetAssistants(assistant.bff.api.v1.GetAssistantsRequest) returns (assistant.bff.api.v1.GetAssistantsResponse);
     */
    getAssistants(input: GetAssistantsRequest, options?: RpcOptions): UnaryCall<GetAssistantsRequest, GetAssistantsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAssistantsRequest, GetAssistantsResponse>("unary", this._transport, method, opt, input);
    }
}
