import {Box, useTheme} from '@mui/material';
import {Snackbar} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {ChatPane} from '../ChatPane/ChatPane';
import {ErrorScreen} from '../ErrorScreen/ErrorScreen';
import {ChatScreen} from './ChatScreen';
import {IntroScreen} from './IntroScreen';
import SplashScreen from './SplashScreen/SplashScreen';

const MIN_LOADING_TIME_MS = 1500;

export type ResponderReply = {
  message: string;
  suggestions: string[];
};

type SingleTurnChatWindowProps = {
  isMobileEmbed: boolean;
  onStartConversation: () => Promise<void>;
  onSendMessage: (message: string) => Promise<ResponderReply | undefined>;
  showError?: boolean;
};

enum ChatState {
  STARTED,
  INTRO,
  NOT_STARTED,
}

function SingleTurnChatWindow(props: SingleTurnChatWindowProps) {
  const theme = useTheme();

  const [chatState, setChatState] = useState<ChatState>(
    props.isMobileEmbed ? ChatState.INTRO : ChatState.NOT_STARTED
  );
  const [userMessage, setUserMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
  const [messageErrorToastText, setMessageErrorToastText] =
    useState<string>('');

  const handleSendMessage = async (message: string) => {
    setChatState(ChatState.STARTED);
    setUserMessage(message);
    setMessage('');

    try {
      const sendMessagePromise = props.onSendMessage(message);

      const [res] = await Promise.all([
        sendMessagePromise,
        delay(MIN_LOADING_TIME_MS),
      ]);

      setMessage(res?.message ?? '');
      setSuggestedQuestions(res?.suggestions ?? []);
    } catch (error) {
      setMessageErrorToastText('Something went wrong. Try again.');
    }
  };

  const startAssistant = () => {
    setChatState(ChatState.INTRO);
    props.onStartConversation();
  };

  useEffect(() => {
    if (props.isMobileEmbed) {
      startAssistant();
    }
  }, []);

  if (props.showError) {
    return <ErrorScreen />;
  }

  if (chatState === ChatState.NOT_STARTED) {
    return <SplashScreen onButtonClick={startAssistant} />;
  }

  return (
    <ChatPane
      isMobileEmbed={props.isMobileEmbed}
      onUserInput={message => handleSendMessage(message)}
      onEndSession={() => setChatState(ChatState.NOT_STARTED)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
          }}
        >
          {chatState === ChatState.INTRO && (
            <IntroScreen
              onSuggestedQuestionSelected={question => {
                handleSendMessage(question);
                setChatState(ChatState.STARTED);
              }}
            />
          )}

          {chatState === ChatState.STARTED && (
            <ChatScreen
              userMessage={userMessage}
              message={message}
              suggestedQuestions={suggestedQuestions}
              handleSendMessage={handleSendMessage}
            />
          )}
        </Box>
        <Snackbar
          color="general"
          open={messageErrorToastText.length > 0}
          autoHideDuration={3000}
          onClose={() => {
            setMessageErrorToastText('');
          }}
          text={messageErrorToastText}
          // Some custom styling to get it to live within Assistant window and match mocks
          sx={{
            position: 'absolute',
            zIndex: 0,
            // Unfortunately MUI Snackbars were not made to be used within sub-windows as we are
            // Without these !important statements, MUI applies transform and left on top of it and
            // it looks off.
            transform: 'none !important',
            left: 'auto !important',
            bottom: '0',
          }}
          ContentProps={{sx: {height: 64, pt: 0, pb: 0, mt: 0, mb: 0}}}
        />
      </Box>
    </ChatPane>
  );
}

function delay(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default SingleTurnChatWindow;
