import {AssistantMessage} from '../Messages/AssistantMessage/AssistantMessage';
import {UserMessage} from '../Messages/UserMessage/UserMessage';
import {MultiTurnMessage} from './MultiTurnChatWindow';

type ChatScreenProps = {
  messages: MultiTurnMessage[];
};

export function MultiTurnChatScreen(props: ChatScreenProps) {
  const {messages} = props;

  // TODO: add <UserMessage /> for user messages. Component needs to be created.
  return (
    <>
      {messages.map(message => (
        <>
          {message.sender === 'assistant' && (
            <AssistantMessage
              message={message.message}
              isLoading={message.isLoading}
              hasFeedback={message.hasFeedback}
              assistantName="Sal | Verily Me AI Assistant"
            />
          )}
          {message.sender === 'user' && (
            <UserMessage message={message.message} initials="DB" />
          )}
        </>
      ))}
    </>
  );
}
