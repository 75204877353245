import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AssistantMessage} from '../Messages/AssistantMessage/AssistantMessage';
import {SuggestedQuestions} from './SuggestedQuestions';
import {Topic} from './Topic/Topic';

type ChatScreenProps = {
  userMessage: string;
  message: string;
  suggestedQuestions?: string[];
  handleSendMessage: (message: string) => void;
};

export function ChatScreen(props: ChatScreenProps) {
  const {
    userMessage,
    message,
    suggestedQuestions = [],
    handleSendMessage,
  } = props;

  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Topic />
        {userMessage && (
          <Typography variant="display6">{userMessage}</Typography>
        )}
      </Box>

      <AssistantMessage
        message={message}
        isLoading={!message}
        hasFeedback={false}
        assistantName={t('assistant-title-full')}
      />

      {message && suggestedQuestions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Typography variant="body1em">{t('tell-me-more-about')}</Typography>
          <SuggestedQuestions
            suggestedQuestions={suggestedQuestions}
            onSelected={question => handleSendMessage(question)}
          />
        </Box>
      )}
    </>
  );
}
