import {createContext, useContext} from 'react';

type ButtonPlacement = 'inside' | 'outside';

export type AssistantConfig = {
  sendButtonPlacement?: ButtonPlacement;
  isFeedbackEnabled?: boolean;
  hasTitleBar?: boolean;
  supportMarkdown?: boolean;
  hasTypeSomethingPlaceholder?: boolean;
};

const defaultAssistantConfig: AssistantConfig = {
  sendButtonPlacement: 'inside',
  isFeedbackEnabled: false,
  hasTitleBar: false,
  supportMarkdown: false,
  hasTypeSomethingPlaceholder: false,
};

export const AssistantConfigContext = createContext(defaultAssistantConfig);

export const useAssistantConfigContext = () => {
  return useContext(AssistantConfigContext);
};

export function AssistantConfigProvider(props: {
  children: React.ReactNode;
  sendButtonPlacement?: ButtonPlacement;
  isFeedbackEnabled?: boolean;
  hasTitleBar?: boolean;
  supportMarkdown?: boolean;
  hasTypeSomethingPlaceholder?: boolean;
}) {
  const value = {
    sendButtonPlacement: props.sendButtonPlacement,
    isFeedbackEnabled: props.isFeedbackEnabled,
    hasTitleBar: props.hasTitleBar,
    supportMarkdown: props.supportMarkdown,
    hasTypeSomethingPlaceholder: props.hasTypeSomethingPlaceholder,
  };

  return (
    <AssistantConfigContext.Provider value={value}>
      {props.children}
    </AssistantConfigContext.Provider>
  );
}
