import {Box, useTheme} from '@mui/material';
import useIsMobile from '../../utilities/useIsMobile';
import useIsTablet from '../../utilities/useIsTablet';
import {InputArea} from './InputArea/InputArea';
import {MaybeDrawer} from './MaybeDrawer/MaybeDrawer';

type ChatPaneProps = {
  children?: React.ReactNode;
  isMobileEmbed?: boolean;
  onUserInput?: (input: string) => void;
  onEndSession?: () => void;
};

export function ChatPane(props: ChatPaneProps) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const {onUserInput} = props;

  let horizontalPadding = theme.spacing(3);

  if (isMobile) {
    horizontalPadding = theme.spacing(2);
  } else if (isTablet) {
    horizontalPadding = theme.spacing(4);
  }

  return (
    <MaybeDrawer
      isMobileEmbed={props.isMobileEmbed}
      onClose={() => {
        props.onEndSession && props.onEndSession();
      }}
    >
      <Box
        sx={{
          paddingTop: isTablet || isMobile ? 0 : theme.spacing(2),
          paddingLeft: horizontalPadding,
          paddingRight: horizontalPadding,
          flexGrow: 1,
          overflowY: 'auto',
        }}
        data-testid="chat-card-content"
      >
        {props.children}
      </Box>

      <Box
        sx={{
          borderTopWidth: '0.5px',
          borderTopStyle: 'solid',
          borderTopColor: theme.palette.background.separator,
          flexShrink: 0,
          flexGrow: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(2),
            paddingBottom:
              isTablet || isMobile ? theme.spacing(2) : theme.spacing(4),
            paddingRight: horizontalPadding,
            paddingLeft: horizontalPadding,
          }}
        >
          <InputArea onUserInput={onUserInput} />
        </Box>
      </Box>
    </MaybeDrawer>
  );
}
