import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ConversationalAssistant} from '../Assistant/ConversationalAssistant';

type RootProps = {
  name: string;
};

export default function Root(_props: RootProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ConversationalAssistant />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
