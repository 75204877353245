/**
 * @fileoverview Custom base error class for better error handling.
 */

/**
 * Base class for custom errors that extends the native `Error` class.
 * Helpful to create custom error classes with a specific name/type for
 * better error handling/unit testing.
 *
 * @template T - A string literal type representing the name of the error.
 *
 * @extends {Error}
 *
 * @example
 * // Create a custom error class
 * type MyServiceErrorNames =
 *  | 'SERVICE_GET_ERROR'
 *  | 'SERVICE_TIMEOUT_ERROR'
 *
 * class MyServiceError extends ErrorBase<MyServiceErrorNames> {}
 *
 * // Usage for a producer
 * try {
 *   // Some code that throws a random/unknown error
 * } catch (error) {
 *   throw new MyServiceError({
 *     name: 'SERVICE_GET_ERROR',
 *     message: 'Error getting data from the service',
 *     cause: error,
 *   });
 * }
 *
 * // Usage for a consumer of the error
 * try {
 *   // Some code that may throw a MyServiceError
 * } catch (error) {
 *   if (error instanceof MyServiceError) {
 *     // Handle the error in some special way
 *   } else {
 *     // Handle the error in a generic way
 *   }
 * }
 *
 */
export class ErrorBase<T extends string> extends Error {
  override name: T;

  constructor({name, message, cause}: {name: T; message: string; cause?: any}) {
    super(message, {cause});
    this.name = name;

    // Capture the stack trace, excluding the constructor call from the stack trace.
    Error.captureStackTrace(this, this.constructor);
  }
}
