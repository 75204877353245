import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function ChatDisclaimer() {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Box>
      <Typography
        data-testid="disclaimer"
        variant="body2"
        sx={{
          color: theme.palette.text.muted,
        }}
      >
        {t('chat-disclaimer-text')}
      </Typography>
    </Box>
  );
}
