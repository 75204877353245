import {Box, useTheme} from '@mui/material';
import {Snackbar} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {ChatPane} from '../ChatPane/ChatPane';
import {useAssistantConfigContext} from '../ConfigContext/ConfigContext';
import {ErrorScreen} from '../ErrorScreen/ErrorScreen';
import {MultiTurnChatScreen} from './MultiTurnChatScreen';
import {TitleBar} from './TitleBar/TitleBar';

const MIN_LOADING_TIME_MS = 1500;

export type MultiTurnResponderReply = {
  message: string;
};

export type MultiTurnMessage = {
  message: string;
  isLoading: boolean;
  sender: 'user' | 'assistant';
  hasFeedback: boolean;
};

type ChatWindowProps = {
  isMobileEmbed: boolean;
  onStartConversation: () => Promise<string>;
  onSendMessage: (
    message: string
  ) => Promise<MultiTurnResponderReply | undefined>;
  showError?: boolean;
};

function MultiTurnChatWindow(props: ChatWindowProps) {
  const theme = useTheme();
  const {hasTitleBar} = useAssistantConfigContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [messages, setMessages] = useState<MultiTurnMessage[]>([]);

  const handleSendMessage = async (message: string) => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message,
        sender: 'user',
        isLoading: false,
        hasFeedback: false,
      },
      {
        message: '',
        sender: 'assistant',
        isLoading: true,
        hasFeedback: false,
      },
    ]);

    try {
      // remove hasFeedback from all messages
      setMessages(prevMessages =>
        prevMessages.map(msg => ({...msg, hasFeedback: false}))
      );

      const sendMessagePromise = props.onSendMessage(message);

      const [res] = await Promise.all([
        sendMessagePromise,
        delay(MIN_LOADING_TIME_MS),
      ]);

      // find last assistant message and update it with the response
      setMessages(prevMessages => {
        const lastAssistantMessageIndex = prevMessages
          .map((msg, index) => (msg.sender === 'assistant' ? index : -1))
          .filter(index => index !== -1)
          .pop();

        if (lastAssistantMessageIndex === undefined) {
          return prevMessages;
        }

        const newMessages = [...prevMessages];
        newMessages[lastAssistantMessageIndex] = {
          message: res?.message ?? '',
          sender: 'assistant',
          isLoading: false,
          hasFeedback: true,
        };

        return newMessages;
      });
    } catch (error) {
      console.error(error);

      // remove last (assistant) message
      setMessages(prevMessages => {
        prevMessages.pop();
        return prevMessages;
      });

      setErrorMessage('Something went wrong. Try again.');
      // clear error message in 4 seconds
      // TODO: Use prop to automatically handle visibility https://github.com/verily-src/verily1/blob/main/vds/rds/src/components/Snackbar/Snackbar.tsx#L225
      delay(4000).then(() => setErrorMessage(undefined));
    }
  };

  const startAssistant = async () => {
    setMessages([
      {
        sender: 'assistant',
        message: '',
        isLoading: true,
        hasFeedback: false,
      },
    ]);
    const welcomeMessage = await props.onStartConversation();
    if (!welcomeMessage) {
      return;
    }

    setMessages([
      {
        sender: 'assistant',
        message: welcomeMessage,
        isLoading: false,
        hasFeedback: true,
      },
    ]);
  };

  useEffect(() => {
    startAssistant();
  }, []);

  if (props.showError) {
    return <ErrorScreen />;
  }

  return (
    <>
      {hasTitleBar && <TitleBar />}
      <ChatPane
        isMobileEmbed={props.isMobileEmbed}
        onUserInput={message => handleSendMessage(message)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(3),
            }}
          >
            <MultiTurnChatScreen messages={messages} />
          </Box>
        </Box>
        <Snackbar
          color="general"
          text={errorMessage ?? ''}
          open={errorMessage !== undefined}
          sx={{
            bottom: theme.spacing(11),
          }}
        />
      </ChatPane>
    </>
  );
}

function delay(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default MultiTurnChatWindow;
