import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {Auth} from '@verily-src/auth';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  AskQuestionRequest,
  CreateSessionRequest,
} from '@verily-src/verily1-protos/assistant/bff/api/v1/assistant_bff_service';
import {AssistantBFFServiceClient} from '@verily-src/verily1-protos/assistant/bff/api/v1/assistant_bff_service.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {onLogout} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/logoutUtils';
import {ResponderReply} from '../components/SingleTurnChatWindow/SingleTurnChatWindow';
import {ErrorBase} from '../utilities/errors';

const host = '/api';
const LANGUAGE_TAG = 'en';

/**
 * Represents the possible error names that can occur within the ChatBot service.
 *
 * - `GET_CONFIGURATION_ERROR`: Error related to the GetAssistantConfiguration call.
 * - `CREATE_SESSION_ERROR`: Error related to the CreateSession call.
 * - `SEND_MESSAGE_ERROR`: Error related to the AskQuestion call.
 */
type ChatbotErrorNames =
  | 'GET_CONFIGURATION_ERROR'
  | 'CREATE_SESSION_ERROR'
  | 'SEND_MESSAGE_ERROR';

export class ChatbotError extends ErrorBase<ChatbotErrorNames> {}

export interface IChatBotService {
  getConfiguration(): Promise<boolean>;
  createSession(): Promise<void>;
  sendMessage(message: string): Promise<ResponderReply>;
}

/**
 * Service class for interacting with the ChatBot backend.
 * Implements the IChatBotService interface.
 *
 * Exposed just for testing purposes. Use the default export.
 */
export class ChatBotService implements IChatBotService {
  /**
   * Client for the Assistant BFF Service.
   */
  private assistantServiceClient?: AssistantBFFServiceClient;

  /**
   * Session ID for the current chat session.
   */
  private sessionId?: string;

  /**
   * Name of the participant record in the format expected by the
   * requests/responses of the Assistant BFF Service.
   */
  private participantRecordName?: string;

  /**
   * Constructor for the ChatBotService class.
   * Initializes the service and sets up a logout handler.
   */
  constructor() {
    onLogout(() => (this.sessionId = undefined));
  }

  /**
   * Retrieves the Assistant BFF Service client, initializing it if necessary.
   * @returns The Assistant BFF Service client.
   */
  async getClient() {
    if (!this.assistantServiceClient) {
      const interceptor = await Auth.getInterceptor();

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors: [new LogGrpcUnaryInterceptor(), interceptor],
      });

      this.assistantServiceClient = new AssistantBFFServiceClient(transport);
    }

    return this.assistantServiceClient;
  }

  /**
   * Retrieves the configuration for the assistant.
   * @returns A promise that resolves to a boolean indicating whether the assistant is enabled.
   * @throws Will throw an error if the configuration retrieval fails or
   *  if the assistant is enabled for more than one participant record.
   */
  async getConfiguration(): Promise<boolean> {
    const client = await this.getClient();

    try {
      const response = (await client.getAssistantConfiguration({})).response;
      if (!response.enabled) {
        return false;
      }

      if (response.participantRecords.length !== 1) {
        throw new ChatbotError({
          name: 'GET_CONFIGURATION_ERROR',
          message: `Expected assistant to be enabled for exactly one participant record, but found it was enabled for ${response.participantRecords.length} participant records`,
          cause: null,
        });
      }
      this.participantRecordName =
        response.participantRecords[0].participantRecordName;
      return true;
    } catch (err) {
      console.error('Error getting configuration', err);
      throw err;
    }
  }

  /**
   * Creates a new chat session.
   * @throws ChatborError if the participant record name is not set or if the session creation fails.
   */
  async createSession(): Promise<void> {
    // TODO: move to a strongly typed singleton
    const assistantConfigurabilityEnabled = config.getBoolean(
      'ASSISTANT_CONFIGURED_CAPABILITY'
    );
    if (assistantConfigurabilityEnabled && !this.participantRecordName) {
      throw new ChatbotError({
        name: 'CREATE_SESSION_ERROR',
        message: 'Participant record name is not set',
        cause: null,
      });
    }
    // Remove this line after assistantConfigurabilityEnabled is removed.
    this.participantRecordName = this.participantRecordName || '';

    const request: CreateSessionRequest = {
      languageTag: LANGUAGE_TAG,
      participantRecord: this.participantRecordName!,
    };

    const client = await this.getClient();

    try {
      const response = (await client.createSession(request)).response;
      this.sessionId = response.sessionId;
    } catch (err) {
      console.error('Error creating session', err);
      throw new ChatbotError({
        name: 'CREATE_SESSION_ERROR',
        message: 'Error calling createSession',
        cause: err,
      });
    }
  }

  /**
   * Sends a message to the assistant and retrieves the response.
   * @param message - The message to send.
   * @returns A promise that resolves to the assistant's reply.
   * @throws Will throw an error if the message sending fails.
   */
  async sendMessage(message: string): Promise<ResponderReply> {
    const sessionId = await this.getSessionId();
    const request: AskQuestionRequest = {
      sessionId: sessionId,
      languageTag: LANGUAGE_TAG,
      questionText: message,
    };

    const client = await this.getClient();

    try {
      const response = (await client.askQuestion(request)).response;
      return {
        message: response.response,
        suggestions: response.followUps,
      };
    } catch (err) {
      console.error('Error sending message', err);
      throw err;
    }
  }

  /**
   * Retrieves the current session ID, creating a new session if necessary.
   * @returns A promise that resolves to the session ID.
   */
  async getSessionId(): Promise<string> {
    if (!this.sessionId) {
      await this.createSession();
    }

    return this.sessionId!;
  }
}

export default new ChatBotService();
