/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "assistant/bff/api/v1/assistant_bff_service.proto" (package "assistant.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * A request to ask a question to the Assistant for a given session.
 *
 * @generated from protobuf message assistant.bff.api.v1.AskQuestionRequest
 */
export interface AskQuestionRequest {
    /**
     * The language to use for the Assistant.
     *
     * @generated from protobuf field: string language_tag = 1;
     */
    languageTag: string;
    /**
     * The session the user is interacting with.
     *
     * @generated from protobuf field: string session_id = 2;
     */
    sessionId: string;
    /**
     * The question to ask the Assistant.
     *
     * @generated from protobuf field: string question_text = 3;
     */
    questionText: string;
}
/**
 * A response to a question asked to the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.AskQuestionResponse
 */
export interface AskQuestionResponse {
    /**
     * The response to the question asked to the Assistant.
     *
     * @generated from protobuf field: string response = 1;
     */
    response: string;
    /**
     * Follow-up questions to ask the User.
     *
     * @generated from protobuf field: repeated string follow_ups = 2;
     */
    followUps: string[];
}
/**
 * A request to ask a multi-turn question to the Assistant for a given session.
 *
 * @generated from protobuf message assistant.bff.api.v1.AskMultiTurnQuestionRequest
 */
export interface AskMultiTurnQuestionRequest {
    /**
     * The question the user had asked.
     *
     * @generated from protobuf field: string question_text = 1;
     */
    questionText: string;
    /**
     * The session the user is interacting with.
     *
     * @generated from protobuf field: string session_id = 2;
     */
    sessionId: string;
    /**
     * The assistant that should respond to the user.
     *
     * @generated from protobuf field: string assistant = 3;
     */
    assistant: string;
}
/**
 * A response to a multi-turn question asked to the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.AskMultiTurnQuestionResponse
 */
export interface AskMultiTurnQuestionResponse {
    /**
     * The response from the Assistant.
     *
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * A request to create a new single-turn session with the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * The language to use for the Assistant.
     *
     * @generated from protobuf field: string language_tag = 1;
     */
    languageTag: string;
    /**
     * The participant record associated with the session.
     * Format: participantRecords/{participant_record}.
     *
     * @generated from protobuf field: string participant_record = 2;
     */
    participantRecord: string;
}
/**
 * A response to a request to create a new single-turn session with the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.CreateSessionResponse
 */
export interface CreateSessionResponse {
    /**
     * The first message to display to the user.
     *
     * @generated from protobuf field: string welcome_text = 1;
     */
    welcomeText: string;
    /**
     * Suggest questions for the user to ask the assistant
     *
     * @generated from protobuf field: repeated string suggested_questions = 2;
     */
    suggestedQuestions: string[];
    /**
     * The UUID of the newly created session.
     *
     * @generated from protobuf field: string session_id = 3;
     */
    sessionId: string;
}
/**
 * A request to create a new multi-turn session with the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.CreateMultiTurnSessionRequest
 */
export interface CreateMultiTurnSessionRequest {
}
/**
 * A response to a request to create a new multi-turn session with the Assistant.
 *
 * @generated from protobuf message assistant.bff.api.v1.CreateMultiTurnSessionResponse
 */
export interface CreateMultiTurnSessionResponse {
    /**
     * The first message to be displayed to the user.
     *
     * @generated from protobuf field: string welcome_text = 1;
     */
    welcomeText: string;
    /**
     * The UUID of the newly created session.
     *
     * @generated from protobuf field: string session_id = 2;
     */
    sessionId: string;
}
/**
 * A request to get the Assistant configuration to determine
 * if Assistant is enabled for a given user.
 *
 * @generated from protobuf message assistant.bff.api.v1.GetAssistantConfigurationRequest
 */
export interface GetAssistantConfigurationRequest {
}
/**
 * The Assistant configuration to determine if Assistant is enabled for a given user.
 *
 * @generated from protobuf message assistant.bff.api.v1.AssistantConfiguration
 */
export interface AssistantConfiguration {
    /**
     * Whether Assistant is enabled for the user.
     *
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * The list of participant records that Assistant is enabled for.
     *
     * @generated from protobuf field: repeated assistant.bff.api.v1.ParticipantRecord participant_records = 2;
     */
    participantRecords: ParticipantRecord[];
}
/**
 * Wrapper around a participant record.
 *
 * @generated from protobuf message assistant.bff.api.v1.ParticipantRecord
 */
export interface ParticipantRecord {
    /**
     * TODO: rename this field to participant_record
     * Format: participantRecords/{participant_record_id}.
     *
     * @generated from protobuf field: string participant_record_name = 1;
     */
    participantRecordName: string;
}
/**
 * A request to get the list of assistants for a given user.
 *
 * @generated from protobuf message assistant.bff.api.v1.GetAssistantsRequest
 */
export interface GetAssistantsRequest {
}
/**
 * The response to a request to get the list of assistants for a given user.
 *
 * @generated from protobuf message assistant.bff.api.v1.GetAssistantsResponse
 */
export interface GetAssistantsResponse {
    /**
     * The list of assistants for the user.
     *
     * @generated from protobuf field: repeated string assistants = 1;
     */
    assistants: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AskQuestionRequest$Type extends MessageType<AskQuestionRequest> {
    constructor() {
        super("assistant.bff.api.v1.AskQuestionRequest", [
            { no: 1, name: "language_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "question_text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<AskQuestionRequest>): AskQuestionRequest {
        const message = { languageTag: "", sessionId: "", questionText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AskQuestionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AskQuestionRequest): AskQuestionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string language_tag */ 1:
                    message.languageTag = reader.string();
                    break;
                case /* string session_id */ 2:
                    message.sessionId = reader.string();
                    break;
                case /* string question_text */ 3:
                    message.questionText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AskQuestionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string language_tag = 1; */
        if (message.languageTag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageTag);
        /* string session_id = 2; */
        if (message.sessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
        /* string question_text = 3; */
        if (message.questionText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.questionText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.AskQuestionRequest
 */
export const AskQuestionRequest = new AskQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AskQuestionResponse$Type extends MessageType<AskQuestionResponse> {
    constructor() {
        super("assistant.bff.api.v1.AskQuestionResponse", [
            { no: 1, name: "response", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "follow_ups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AskQuestionResponse>): AskQuestionResponse {
        const message = { response: "", followUps: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AskQuestionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AskQuestionResponse): AskQuestionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string response */ 1:
                    message.response = reader.string();
                    break;
                case /* repeated string follow_ups */ 2:
                    message.followUps.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AskQuestionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string response = 1; */
        if (message.response !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.response);
        /* repeated string follow_ups = 2; */
        for (let i = 0; i < message.followUps.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.followUps[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.AskQuestionResponse
 */
export const AskQuestionResponse = new AskQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AskMultiTurnQuestionRequest$Type extends MessageType<AskMultiTurnQuestionRequest> {
    constructor() {
        super("assistant.bff.api.v1.AskMultiTurnQuestionRequest", [
            { no: 1, name: "question_text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "assistant", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "verily.health/Assistant" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<AskMultiTurnQuestionRequest>): AskMultiTurnQuestionRequest {
        const message = { questionText: "", sessionId: "", assistant: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AskMultiTurnQuestionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AskMultiTurnQuestionRequest): AskMultiTurnQuestionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string question_text */ 1:
                    message.questionText = reader.string();
                    break;
                case /* string session_id */ 2:
                    message.sessionId = reader.string();
                    break;
                case /* string assistant */ 3:
                    message.assistant = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AskMultiTurnQuestionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string question_text = 1; */
        if (message.questionText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.questionText);
        /* string session_id = 2; */
        if (message.sessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
        /* string assistant = 3; */
        if (message.assistant !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.assistant);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.AskMultiTurnQuestionRequest
 */
export const AskMultiTurnQuestionRequest = new AskMultiTurnQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AskMultiTurnQuestionResponse$Type extends MessageType<AskMultiTurnQuestionResponse> {
    constructor() {
        super("assistant.bff.api.v1.AskMultiTurnQuestionResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AskMultiTurnQuestionResponse>): AskMultiTurnQuestionResponse {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AskMultiTurnQuestionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AskMultiTurnQuestionResponse): AskMultiTurnQuestionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AskMultiTurnQuestionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.AskMultiTurnQuestionResponse
 */
export const AskMultiTurnQuestionResponse = new AskMultiTurnQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSessionRequest$Type extends MessageType<CreateSessionRequest> {
    constructor() {
        super("assistant.bff.api.v1.CreateSessionRequest", [
            { no: 1, name: "language_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "participant_record", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "verily.health/ParticipantRecord" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<CreateSessionRequest>): CreateSessionRequest {
        const message = { languageTag: "", participantRecord: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSessionRequest): CreateSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string language_tag */ 1:
                    message.languageTag = reader.string();
                    break;
                case /* string participant_record */ 2:
                    message.participantRecord = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string language_tag = 1; */
        if (message.languageTag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageTag);
        /* string participant_record = 2; */
        if (message.participantRecord !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.participantRecord);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.CreateSessionRequest
 */
export const CreateSessionRequest = new CreateSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSessionResponse$Type extends MessageType<CreateSessionResponse> {
    constructor() {
        super("assistant.bff.api.v1.CreateSessionResponse", [
            { no: 1, name: "welcome_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "suggested_questions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSessionResponse>): CreateSessionResponse {
        const message = { welcomeText: "", suggestedQuestions: [], sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSessionResponse): CreateSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string welcome_text */ 1:
                    message.welcomeText = reader.string();
                    break;
                case /* repeated string suggested_questions */ 2:
                    message.suggestedQuestions.push(reader.string());
                    break;
                case /* string session_id */ 3:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string welcome_text = 1; */
        if (message.welcomeText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.welcomeText);
        /* repeated string suggested_questions = 2; */
        for (let i = 0; i < message.suggestedQuestions.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.suggestedQuestions[i]);
        /* string session_id = 3; */
        if (message.sessionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.CreateSessionResponse
 */
export const CreateSessionResponse = new CreateSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMultiTurnSessionRequest$Type extends MessageType<CreateMultiTurnSessionRequest> {
    constructor() {
        super("assistant.bff.api.v1.CreateMultiTurnSessionRequest", []);
    }
    create(value?: PartialMessage<CreateMultiTurnSessionRequest>): CreateMultiTurnSessionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateMultiTurnSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMultiTurnSessionRequest): CreateMultiTurnSessionRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateMultiTurnSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.CreateMultiTurnSessionRequest
 */
export const CreateMultiTurnSessionRequest = new CreateMultiTurnSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMultiTurnSessionResponse$Type extends MessageType<CreateMultiTurnSessionResponse> {
    constructor() {
        super("assistant.bff.api.v1.CreateMultiTurnSessionResponse", [
            { no: 1, name: "welcome_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateMultiTurnSessionResponse>): CreateMultiTurnSessionResponse {
        const message = { welcomeText: "", sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateMultiTurnSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMultiTurnSessionResponse): CreateMultiTurnSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string welcome_text */ 1:
                    message.welcomeText = reader.string();
                    break;
                case /* string session_id */ 2:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMultiTurnSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string welcome_text = 1; */
        if (message.welcomeText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.welcomeText);
        /* string session_id = 2; */
        if (message.sessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.CreateMultiTurnSessionResponse
 */
export const CreateMultiTurnSessionResponse = new CreateMultiTurnSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssistantConfigurationRequest$Type extends MessageType<GetAssistantConfigurationRequest> {
    constructor() {
        super("assistant.bff.api.v1.GetAssistantConfigurationRequest", []);
    }
    create(value?: PartialMessage<GetAssistantConfigurationRequest>): GetAssistantConfigurationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssistantConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssistantConfigurationRequest): GetAssistantConfigurationRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAssistantConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.GetAssistantConfigurationRequest
 */
export const GetAssistantConfigurationRequest = new GetAssistantConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssistantConfiguration$Type extends MessageType<AssistantConfiguration> {
    constructor() {
        super("assistant.bff.api.v1.AssistantConfiguration", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "participant_records", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ParticipantRecord }
        ]);
    }
    create(value?: PartialMessage<AssistantConfiguration>): AssistantConfiguration {
        const message = { enabled: false, participantRecords: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssistantConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssistantConfiguration): AssistantConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated assistant.bff.api.v1.ParticipantRecord participant_records */ 2:
                    message.participantRecords.push(ParticipantRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssistantConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated assistant.bff.api.v1.ParticipantRecord participant_records = 2; */
        for (let i = 0; i < message.participantRecords.length; i++)
            ParticipantRecord.internalBinaryWrite(message.participantRecords[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.AssistantConfiguration
 */
export const AssistantConfiguration = new AssistantConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantRecord$Type extends MessageType<ParticipantRecord> {
    constructor() {
        super("assistant.bff.api.v1.ParticipantRecord", [
            { no: 1, name: "participant_record_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ParticipantRecord>): ParticipantRecord {
        const message = { participantRecordName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantRecord): ParticipantRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string participant_record_name */ 1:
                    message.participantRecordName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string participant_record_name = 1; */
        if (message.participantRecordName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.participantRecordName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.ParticipantRecord
 */
export const ParticipantRecord = new ParticipantRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssistantsRequest$Type extends MessageType<GetAssistantsRequest> {
    constructor() {
        super("assistant.bff.api.v1.GetAssistantsRequest", []);
    }
    create(value?: PartialMessage<GetAssistantsRequest>): GetAssistantsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssistantsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssistantsRequest): GetAssistantsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAssistantsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.GetAssistantsRequest
 */
export const GetAssistantsRequest = new GetAssistantsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssistantsResponse$Type extends MessageType<GetAssistantsResponse> {
    constructor() {
        super("assistant.bff.api.v1.GetAssistantsResponse", [
            { no: 1, name: "assistants", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "verily.health/Assistant" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetAssistantsResponse>): GetAssistantsResponse {
        const message = { assistants: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssistantsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssistantsResponse): GetAssistantsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string assistants */ 1:
                    message.assistants.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssistantsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string assistants = 1; */
        for (let i = 0; i < message.assistants.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.assistants[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message assistant.bff.api.v1.GetAssistantsResponse
 */
export const GetAssistantsResponse = new GetAssistantsResponse$Type();
/**
 * @generated ServiceType for protobuf service assistant.bff.api.v1.AssistantBFFService
 */
export const AssistantBFFService = new ServiceType("assistant.bff.api.v1.AssistantBFFService", [
    { name: "CreateSession", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: CreateSessionRequest, O: CreateSessionResponse },
    { name: "AskQuestion", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: AskQuestionRequest, O: AskQuestionResponse },
    { name: "CreateMultiTurnSession", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: CreateMultiTurnSessionRequest, O: CreateMultiTurnSessionResponse },
    { name: "AskMultiTurnQuestion", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: AskMultiTurnQuestionRequest, O: AskMultiTurnQuestionResponse },
    { name: "GetAssistantConfiguration", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAssistantConfigurationRequest, O: AssistantConfiguration },
    { name: "GetAssistants", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAssistantsRequest, O: GetAssistantsResponse }
]);
