import {Box, Card, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ErrorImage from './ErrorImage';

export function ErrorScreen() {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        padding: 0,
        // TODO(ONEVERILY-37669): [Cleanup] Width x Height pixels to constants
        maxWidth: 572,
        minWidth: 343,
        width: '100%',
        borderRadius: '24px',
        marginBottom: theme.spacing(2),
        height: 'max-content',
      }}
      data-testid="assistant-error-screen"
    >
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        data-testid="error-box"
      >
        <Box
          sx={{
            p: 5,
          }}
        >
          {ErrorImage}
        </Box>
        <Box sx={{mb: 1}}>
          <Typography variant="display6">
            {t('something-went-wrong')}
          </Typography>
        </Box>
        <Box sx={{maxWidth: 327}}>
          <Typography
            variant="body1"
            color={theme.palette.text.muted}
            sx={{fontSize: '1rem', mb: 3}}
          >
            {t('were-having-an-issue')}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
