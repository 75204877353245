import {config} from '@verily-src/phaf-runtime-helpers';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {useState} from 'react';
import multiTurnChatBotService from '../../services/MultiTurnChatBotService';
import {AssistantConfigProvider} from '../ConfigContext/ConfigContext';
import MultiTurnChatWindow, {
  MultiTurnResponderReply,
} from '../MultiTurnChatWindow/MultiTurnChatWindow';

export function ConversationalAssistant() {
  const [showError, setShowError] = useState(false);

  const assistantDarkModeEnabled = config.getBoolean(
    'ASSISTANT_DARK_MODE_ENABLED'
  );

  const onSendMessage = async (
    message: string
  ): Promise<MultiTurnResponderReply> => {
    return multiTurnChatBotService.sendMessage(message);
  };

  const onStartConversation = async (): Promise<string> => {
    try {
      const welcomeMessage = await multiTurnChatBotService.createSession();
      setShowError(false);
      return welcomeMessage;
    } catch (err) {
      // Fails when creating a session using local overrides.
      // Might need to comment out below line.
      setShowError(true);
    }
    return '';
  };

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={assistantDarkModeEnabled}>
      <AssistantConfigProvider
        sendButtonPlacement="outside"
        isFeedbackEnabled={true}
        hasTitleBar={true}
        supportMarkdown={true}
        hasTypeSomethingPlaceholder={true}
      >
        <MultiTurnChatWindow
          isMobileEmbed={true}
          onStartConversation={onStartConversation}
          onSendMessage={onSendMessage}
          showError={showError}
        />
      </AssistantConfigProvider>
    </VerilyMeThemeProvider>
  );
}

export default ConversationalAssistant;
