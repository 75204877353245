import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import assistantAvatar from '../../../assets/assistantAvatar.svg';
import {useAssistantConfigContext} from '../../ConfigContext/ConfigContext';
import {MessageBubble} from '../MessageBubble/MessageBubble';
import {MessageFeedback} from '../MessageFeedback/MessageFeedback';

type AssistantMessageProps = {
  message: string;
  isLoading: boolean;
  assistantName: string;
  hasFeedback: boolean;
};

export function AssistantMessage(props: AssistantMessageProps) {
  const theme = useTheme();
  const {message, isLoading, assistantName, hasFeedback} = props;
  const {t} = useTranslation();
  const assistantConfig = useAssistantConfigContext();

  return (
    <Box
      data-testid="assistant-message"
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          width: '100%',
        }}
      >
        <Box>
          <img
            src={assistantAvatar}
            alt={t('assistant-title')}
            style={{
              width: '28px',
              height: '28px',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            flexGrow: 1,
          }}
        >
          <Box>
            <Typography variant="body2">{assistantName}</Typography>
          </Box>
          <MessageBubble
            sender="assistant"
            message={message}
            isLoading={isLoading}
            backgroundColor={theme.palette.background.paper}
            borderColor={theme.palette.background.separator}
          />
          {assistantConfig.isFeedbackEnabled && hasFeedback && !isLoading && (
            <MessageFeedback />
          )}
        </Box>
      </Box>
    </Box>
  );
}
