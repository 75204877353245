import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ChatDisclaimer} from './ChatDisclaimer';
import {SuggestedQuestions} from './SuggestedQuestions';
import {Topic} from './Topic/Topic';

type IntroScreenProps = {
  onSuggestedQuestionSelected: (question: string) => void;
};

export function IntroScreen(props: IntroScreenProps) {
  const theme = useTheme();
  const {t} = useTranslation();

  const introQuestions = [
    t('intro-question-how-is-t1d-diagnosed'),
    t('intro-question-how-do-you-treat-t1d'),
    t('intro-question-what-are-some-common-t1d-symptoms'),
    t('intro-question-what-does-it-mean-to-have-high-or-low-blood-sugar'),
  ];

  return (
    <>
      <ChatDisclaimer />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        <Typography variant="body1em">
          {t('try-starting-with-these')}
        </Typography>
        <Topic />
        <SuggestedQuestions
          suggestedQuestions={introQuestions}
          onSelected={props.onSuggestedQuestionSelected}
        />
      </Box>
    </>
  );
}
