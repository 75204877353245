import {Box, Chip, useTheme} from '@mui/material';

type SuggestedQuestionsProps = {
  suggestedQuestions: string[];
  onSelected: (question: string) => void;
};

export function SuggestedQuestions(props: SuggestedQuestionsProps) {
  const theme = useTheme();
  const {suggestedQuestions} = props;

  if (suggestedQuestions.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
      }}
    >
      {suggestedQuestions.map((question, index) => (
        <Chip
          key={index}
          label={question}
          onClick={() => props.onSelected(question)}
          pendo-assistant-suggestion-chip={question}
          size="medium"
          sx={{
            backgroundColor: theme.palette.primary.background,
            borderRadius: '8px',
            color: theme.palette.primary.textOnBackground,
            fontSize: theme.typography.pxToRem(14),
            '&:hover': {
              backgroundColor: theme.palette.state.hoverOnPrimary,
            },
            '&:active': {
              backgroundColor: theme.palette.state.pressPrimary,
            },
          }}
        />
      ))}
    </Box>
  );
}
