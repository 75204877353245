import {
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt,
} from '@mui/icons-material';
import {Box, IconButton, useTheme} from '@mui/material';
import {useState} from 'react';

type MessageFeedbackProps = {
  // TODO: Wire up a callback for feedback submission
};

type SelectedFeedback = 'like' | 'dislike';

export function MessageFeedback(_props: MessageFeedbackProps) {
  const theme = useTheme();
  const [selectedFeedback, setSelectedFeedback] =
    useState<SelectedFeedback | null>(null);

  const onThumbsUpClick = () => {
    setSelectedFeedback('like');
  };

  const onThumbsDownClick = () => {
    setSelectedFeedback('dislike');
  };

  const thumbsUpIcon =
    selectedFeedback === 'like' ? (
      <ThumbUpAlt fontSize="small" data-testid="thumbs-up-selected" />
    ) : (
      <ThumbUpOffAlt fontSize="small" data-testid="thumbs-up" />
    );

  const thumbsDownIcon =
    selectedFeedback === 'dislike' ? (
      <ThumbDownAlt fontSize="small" data-testid="thumbs-down-selected" />
    ) : (
      <ThumbDownOffAlt fontSize="small" data-testid="thumbs-down" />
    );

  return (
    <Box
      data-testid="message-feedback"
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: theme.spacing(3),
        width: 'fit-content',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        borderColor: theme.palette.neutral.divider,
        borderWidth: '0.5px',
        borderStyle: 'solid',
        marginLeft: 'auto',
        gap: theme.spacing(1),
      }}
    >
      <IconButton
        size="small"
        onClick={onThumbsUpClick}
        sx={{
          padding: 0,
        }}
      >
        {thumbsUpIcon}
      </IconButton>

      <Box
        sx={{
          borderWidth: 0,
          borderRightWidth: '0.5px',
          borderStyle: 'solid',
          borderColor: theme.palette.neutral.divider,
        }}
      ></Box>

      <IconButton
        size="small"
        onClick={onThumbsDownClick}
        sx={{
          padding: 0,
        }}
      >
        {thumbsDownIcon}
      </IconButton>
    </Box>
  );
}
