import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {Auth} from '@verily-src/auth';
import {
  AskMultiTurnQuestionRequest,
  CreateMultiTurnSessionRequest,
} from '@verily-src/verily1-protos/assistant/bff/api/v1/assistant_bff_service';
import {AssistantBFFServiceClient} from '@verily-src/verily1-protos/assistant/bff/api/v1/assistant_bff_service.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {onLogout} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/logoutUtils';
import {MultiTurnResponderReply} from '../components/MultiTurnChatWindow/MultiTurnChatWindow';

const host = '/api';

export interface IMultiTurnChatBotService {
  getConfiguration(): Promise<boolean>;
  createSession(): Promise<string>;
  sendMessage(message: string): Promise<MultiTurnResponderReply>;
}

class MultiTurnChatBotService implements IMultiTurnChatBotService {
  private client?: AssistantBFFServiceClient;
  private sessionId?: string;

  constructor() {
    onLogout(() => (this.sessionId = undefined));
  }

  async getClient() {
    if (!this.client) {
      const interceptor = await Auth.getInterceptor();

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors: [new LogGrpcUnaryInterceptor(), interceptor],
      });

      this.client = new AssistantBFFServiceClient(transport);
    }

    return this.client;
  }

  async getConfiguration(): Promise<boolean> {
    const client = await this.getClient();

    try {
      const response = (await client.getAssistantConfiguration({})).response;
      return response.enabled;
    } catch (err) {
      console.error('Error getting configuration', err);
      throw err;
    }
  }

  async createSession(): Promise<string> {
    const request: CreateMultiTurnSessionRequest = {};

    const client = await this.getClient();

    try {
      const response = (await client.createMultiTurnSession(request)).response;
      this.sessionId = response.sessionId;
      return response.welcomeText;
    } catch (err) {
      console.error('Error creating session', err);
      throw err;
    }
  }

  async sendMessage(message: string): Promise<MultiTurnResponderReply> {
    const sessionId = await this.getSessionId();
    const request: AskMultiTurnQuestionRequest = {
      sessionId: sessionId,
      questionText: message,
      assistant: 'assistants/123',
    };

    const client = await this.getClient();

    try {
      const response = (await client.askMultiTurnQuestion(request)).response;
      return {
        message: response.message,
      };
    } catch (err) {
      console.error('Error sending message', err);
      throw err;
    }
  }

  async getSessionId(): Promise<string> {
    if (!this.sessionId) {
      await this.createSession();
    }

    return this.sessionId!;
  }
}

export default new MultiTurnChatBotService();
