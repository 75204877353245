import {ArrowCircleRight} from '@mui/icons-material';
import {Box, IconButton, InputAdornment, useTheme} from '@mui/material';
import {ArrowUpwardIcon, TextField} from '@verily-src/react-design-system';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAssistantConfigContext} from '../../ConfigContext/ConfigContext';

type InputAreaProps = {
  onUserInput?: (input: string) => void;
};

export function InputArea(props: InputAreaProps) {
  const theme = useTheme();
  const assistantConfig = useAssistantConfigContext();
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {t} = useTranslation();
  const {sendButtonPlacement, hasTypeSomethingPlaceholder} = assistantConfig;

  useEffect(() => {
    // Focus the input element when the component mounts
    inputRef.current?.focus();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (!currentMessage) {
      return;
    }

    setCurrentMessage('');
    if (props.onUserInput) {
      props.onUserInput(currentMessage);
    }
  };

  const insideButton = sendButtonPlacement === 'inside' && (
    <InputAdornment
      position="end"
      tabIndex={currentMessage === '' ? undefined : 0}
      sx={{
        ':focus': {
          outlineColor: theme.palette.primary.main,
        },
      }}
    >
      <IconButton
        data-testid="inside-button"
        onClick={handleSendMessage}
        color="primary"
        aria-label={t('send')}
        title={t('send')}
        aria-disabled={currentMessage === ''}
        pendo-id="assistant-send-button"
      >
        <ArrowCircleRight
          sx={{
            // Using color="main" is not the same as using theme.palette.primary.main, so we need to set the color manually
            color: currentMessage
              ? theme.palette.primary.main
              : theme.palette.icon.disabled,
          }}
        />
      </IconButton>
    </InputAdornment>
  );

  const outsideButton = sendButtonPlacement === 'outside' && (
    <IconButton
      data-testid="outside-button"
      onClick={handleSendMessage}
      color="primary"
      aria-label={t('send')}
      title={t('send')}
      aria-disabled={currentMessage === ''}
      pendo-id="assistant-send-button"
      sx={{
        padding: 0,
        borderRadius: '50%',
        // By default, the background color is not the same as the theme's primary.main, so we need to set it manually
        backgroundColor: currentMessage
          ? theme.palette.primary.main
          : theme.palette.state.disabled,
        height: '40px',
        width: '40px',
        ':hover': {
          // By default, hover changes the background color, so we need to keep it the same
          backgroundColor: currentMessage
            ? theme.palette.primary.main
            : theme.palette.state.disabled,
        },
      }}
    >
      <ArrowUpwardIcon
        sx={{
          // By default, the color is not the same as the theme's primary.textOnMain, so we need to set it manually
          color: currentMessage
            ? theme.palette.primary.textOnMain
            : theme.palette.state.disabled,
        }}
        fontSize="medium"
      />
    </IconButton>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(1),
        width: '100%',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <TextField
          data-testid="chat-card-input"
          fullWidth
          value={currentMessage}
          onChange={e => setCurrentMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={
            hasTypeSomethingPlaceholder
              ? 'Type something'
              : t('chat-card-placeholder')
          }
          inputRef={inputRef}
          inputProps={{
            style: {
              paddingLeft: theme.spacing(1),
            },
          }}
          InputProps={{
            sx: {
              borderRadius: '24px',
              backgroundColor: theme.palette.state.hoverOnSurface,
              // This is how to set the inner background color of the text box
              ':focus-within': {
                backgroundColor: theme.palette.state.hoverOnSurface,
              },
            },
            endAdornment: insideButton,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {outsideButton}
      </Box>
    </Box>
  );
}
