import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import chatbotService from '../../services/ChatBotService';
import {AssistantConfigProvider} from '../ConfigContext/ConfigContext';
import SingleTurnChatWindow, {
  ResponderReply,
} from '../SingleTurnChatWindow/SingleTurnChatWindow';

export function Assistant({isMobileEmbed}: {isMobileEmbed: boolean}) {
  const [showError, setShowError] = useState(false);
  const [assistantEnabled, setAssistantEnabled] = useState<
    boolean | undefined
  >();

  const assistantConfigurabilityEnabled = config.getBoolean(
    'ASSISTANT_CONFIGURED_CAPABILITY'
  );
  const assistantDarkModeEnabled = config.getBoolean(
    'ASSISTANT_DARK_MODE_ENABLED'
  );

  // Shows error page if assistant is not enabled.
  useEffect(() => {
    if (assistantConfigurabilityEnabled) {
      chatbotService.getConfiguration().then(setAssistantEnabled);
    }
  }, []);

  useEffect(() => {
    if (assistantEnabled === false) {
      setShowError(true);
    }
  }, [assistantEnabled]);

  const onSendMessage = async (message: string): Promise<ResponderReply> => {
    const response = await chatbotService.sendMessage(message);
    return response;
  };

  const onStartConversation = async (): Promise<void> => {
    try {
      await chatbotService.createSession();
      setShowError(false);
    } catch (err) {
      // Fails when creating a session using local overrides.
      // Might need to comment out below line.
      setShowError(true);
    }
  };

  if (assistantConfigurabilityEnabled && assistantEnabled === undefined) {
    return null;
  }

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={assistantDarkModeEnabled}>
      <AssistantConfigProvider
        sendButtonPlacement="inside"
        isFeedbackEnabled={false}
      >
        <SingleTurnChatWindow
          isMobileEmbed={isMobileEmbed}
          onStartConversation={onStartConversation}
          onSendMessage={onSendMessage}
          showError={showError}
        />
      </AssistantConfigProvider>
    </VerilyMeThemeProvider>
  );
}
